import React, { useCallback, useRef } from "react";
import cx from "classnames";
import "../textInput/Input.scss";
import "./Select.scss";

export interface Alternative<T> {
  value: T;
  text: string;
  disabled?: boolean;
}

interface Stringifiable {
  toString(): string;
}

interface Props<T extends Stringifiable> {
  alternatives: Alternative<T>[];
  value?: Alternative<T>;
  onChange?(value: Alternative<T>): void;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  name?: string;
  autoComplete?: string;
  "data-test-id"?: string;
}

export function Select<T extends Stringifiable>({
  alternatives,
  value,
  placeholder,
  label,
  disabled,
  onChange,
  name,
  autoComplete,
  "data-test-id": dataTestId,
}: Props<T>) {
  const selectId = useRef("select_" + Math.random().toString(36).substr(2, 9));
  const containerRef = useRef<HTMLDivElement>(null);

  const innerOnChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const alternative = alternatives.find((alternative) => {
        return event.target.value === alternative.value.toString();
      });

      alternative && onChange && onChange(alternative);
    },
    [onChange, alternatives],
  );

  return (
    <div ref={containerRef} className="lysa-ui-input-group">
      <label htmlFor={selectId.current}>{label}</label>
      <div className="lysa-ui-select">
        <select
          id={selectId.current}
          value={value && value.value ? value.value.toString() : ""}
          name={name || selectId.current}
          autoComplete={autoComplete}
          disabled={disabled}
          onChange={innerOnChange}
          data-test-id={dataTestId}
        >
          {placeholder ? (
            <option value={""} disabled>
              {placeholder}
            </option>
          ) : null}
          {alternatives.map((alternative) => (
            <option
              key={`${alternative.text}-${alternative.value}`}
              value={(alternative.value || "").toString()}
              disabled={alternative && alternative.disabled}
            >
              {alternative.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
