import React, { useEffect, useState, VoidFunctionComponent } from "react";
import { Layout } from "../components/layout/Layout";
import { Seo } from "../components/seo";
import { Loader } from "../components/loader/Loader";
import { getLanguage, getNavLink, Language } from "../utils/Localization";
import { Grid } from "../components/layout/grid/Grid";
import { DataDisplay } from "../components/dataDisplay/DataDisplay";
import { navigate, graphql, useStaticQuery } from "gatsby";
import {
  createIntl,
  createIntlCache,
  FormattedDate,
  FormattedNumber,
} from "react-intl";
import { Alternative, Select } from "../components/select/Select";
import { Query } from "../utils/query";
import { dataIndex, Isin, Nav } from "../data/dataPublic";
import { useLocation } from "@gatsbyjs/reach-router";
import "./funds.scss";

type QueryData = {
  lfPageFund: {
    data: {
      attributes: {
        pageTitle: string;
        pageDescription: string;
        fundsTitle: string;
        itemFundName: string;
        itemStartDate: string;
        itemEndDate: string;
        itemNav: string;
        itemCurrency: string;
        itemDate: string;
        itemIsin: string;
        itemReturnOneYear: string;
        itemReturnFiveYears: string;
        itemReturnTenYears: string;
        selectCountryLabel: string;
        allCounties: string;
      };
    };
  };
  lfCountries: {
    data: {
      attributes: {
        short: string;
        lf_fund_documents: {
          data: {
            attributes: {
              isin: Isin;
            };
          }[];
        };
      };
    }[];
  };
};

const SEARCH_COUNTRY = "country";

const Funds: VoidFunctionComponent<{ data: Query<QueryData> }> = ({ data }) => {
  const messages = data.strapi.lfPageFund.data.attributes;
  const countryFundMap = data.strapi.lfCountries.data.reduce((map, country) => {
    map[country.attributes.short] =
      country.attributes.lf_fund_documents.data.map((c) => c.attributes.isin);
    return map;
  }, {} as Record<string, string[]>);

  const currencyAlternativs: Alternative<string>[] =
    data.strapi.lfCountries.data.reduce((list, country) => {
      list = [
        ...list,
        { text: country.attributes.short, value: country.attributes.short },
      ];
      return list;
    }, [] as Alternative<string>[]);

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const selectedCountry = search.get(SEARCH_COUNTRY) ?? undefined;

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: getLanguage(),
      messages: {},
    },
    cache,
  );
  const [funds, setFunds] = useState<Nav[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dataIndex
      .getFunds()
      .then(setFunds)
      .finally(() => setLoading(false));
  }, []);

  return (
    <Layout className="funds">
      <Seo
        title={`Lysa Fonder | ${messages.fundsTitle} ${
          selectedCountry
            ? intl.formatDisplayName(selectedCountry, { type: "region" })
            : ""
        }`}
      />
      <Loader loading={loading}>
        <h1>{messages.pageTitle}</h1>
        <p>{messages.pageDescription}</p>
        <Select
          label={messages.selectCountryLabel}
          alternatives={[
            { text: messages.allCounties, value: "" },
            ...currencyAlternativs.map((alt) => ({
              ...alt,
              text: intl.formatDisplayName(alt.text, { type: "region" }),
            })),
          ]}
          value={
            selectedCountry &&
            Object.values(currencyAlternativs)
              .map((c) => c.value)
              .includes(selectedCountry)
              ? Object.values(currencyAlternativs).find(
                  (c) => c.value === selectedCountry,
                )
              : { text: messages.allCounties, value: "" }
          }
          onChange={(country) => {
            search.set(SEARCH_COUNTRY, country.value);
            navigate(
              `${location.pathname}${
                country.value ? "?" + search.toString() : ""
              }`,
              { replace: true },
            );
          }}
        />

        <h2>{messages.fundsTitle}</h2>
        {funds &&
          (selectedCountry && countryFundMap[selectedCountry]
            ? funds.filter((holding) =>
                countryFundMap[selectedCountry].includes(holding.isin),
              )
            : funds
          ).map((item) => (
            <HoldingItem
              key={`funds-${item.isin}`}
              item={item}
              messages={messages}
            />
          ))}
      </Loader>
    </Layout>
  );
};

export default Funds;

const HoldingItem: VoidFunctionComponent<{
  item: Nav;
  messages: { [key: string]: string };
}> = ({ item, messages }) => (
  <a
    className="fund-item"
    href={getNavLink(`/funds/fund`, {
      search: `?isin=${item.isin}`,
    })}
  >
    <Grid row>
      <Grid col xs={12} md={6}>
        <DataDisplay
          type="list-item"
          title={messages.itemFundName}
          text={`${item.name} (${item.isin})`}
        />
      </Grid>
      <Grid col xs={4} md={2}>
        <DataDisplay
          className="text-right-md text-right-lg"
          type="list-item"
          title={messages.itemNav}
          text={<FormattedNumber value={item.price} />}
        />
      </Grid>
      <Grid col xs={4} md={2}>
        <DataDisplay
          className="text-right"
          type="list-item"
          title={messages.itemCurrency}
          text={item.currency}
        />
      </Grid>
      <Grid col xs={4} md={2}>
        <DataDisplay
          className="text-right"
          type="list-item"
          title={messages.itemDate}
          text={<FormattedDate value={item.date} />}
        />
      </Grid>
    </Grid>
  </a>
);

export const query = graphql`
  query ($locale: STRAPI_I18NLocaleCode!) {
    strapi {
      lfCountries {
        data {
          attributes {
            short
            lf_fund_documents {
              data {
                attributes {
                  isin
                }
              }
            }
          }
        }
      }
      lfPageFund(locale: $locale) {
        data {
          attributes {
            pageTitle
            pageDescription
            fundsTitle
            itemFundName
            itemStartDate
            itemEndDate
            itemNav
            itemCurrency
            itemDate
            itemIsin
            itemReturnOneYear
            itemReturnFiveYears
            itemReturnTenYears
            selectCountryLabel
            allCounties
          }
        }
      }
    }
  }
`;
