import React, { FunctionComponent } from "react";
import { Spinner } from "../spinner/Spinner";

interface Props {
  loading?: boolean;
  children: React.ReactNode;
}

export const Loader: FunctionComponent<Props> = ({ loading, children }) => {
  if (loading) {
    return <Spinner />;
  }
  return <>{children}</>;
};
